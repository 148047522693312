// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-saws-js": () => import("./../../../src/pages/products/saws.js" /* webpackChunkName: "component---src-pages-products-saws-js" */),
  "component---src-pages-subscribed-js": () => import("./../../../src/pages/subscribed.js" /* webpackChunkName: "component---src-pages-subscribed-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-used-equipment-js": () => import("./../../../src/pages/used-equipment.js" /* webpackChunkName: "component---src-pages-used-equipment-js" */),
  "component---src-templates-application-template-js": () => import("./../../../src/templates/application-template.js" /* webpackChunkName: "component---src-templates-application-template-js" */),
  "component---src-templates-brand-template-js": () => import("./../../../src/templates/brand-template.js" /* webpackChunkName: "component---src-templates-brand-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-product-type-template-js": () => import("./../../../src/templates/productType-template.js" /* webpackChunkName: "component---src-templates-product-type-template-js" */),
  "component---src-templates-sub-product-template-js": () => import("./../../../src/templates/subProduct-template.js" /* webpackChunkName: "component---src-templates-sub-product-template-js" */),
  "component---src-templates-used-equipment-template-js": () => import("./../../../src/templates/usedEquipment-template.js" /* webpackChunkName: "component---src-templates-used-equipment-template-js" */)
}

